import LmsApi from "@/services/lms";
import formatLmsOtpTime from "@/utils/formatLmsOtpTime";

const state = {
  timeToEndTimer: null,
  showValidateOtpError: false,
  allowedToValidateOtp: false,
};

const mutations = {
  setTimeToEndTimer (state, time) {
    state.timeToEndTimer = time;
  },
  setShowValidateOtpError (state, show) {
    if (state.showValidateOtpError === show) return;
    state.showValidateOtpError = show;
  },
  setAllowedToValidateOtp (state, status) {
    state.allowedToValidateOtp = status;
  },
};

const actions = {
  async fetchOTPRemainingAttempts () {
    const response = await LmsApi.otpRemainingAttempts();
    return response?.data;
  },
  async fetchOTPRemainingTime () {
    const response = await LmsApi.otpRemainingTime();
    return response?.data;
  },
  async fetchGenerateOTP () {
    const response = await LmsApi.otpGenerate();
    return response?.data;
  },
  async fetchValidateOTP (_, code) {
    const response = await LmsApi.otpValidate({ code });
    return response?.data;
  },
  async resendOTPCode ({ dispatch }) {
    await dispatch("check4DigsOTPStatus");
  },
  setShowValidateOtpError ({ commit }, show) {
    commit("setShowValidateOtpError", show);
  },
  changeTimeToEndTimer ({ commit }, time) {
    commit("setTimeToEndTimer", time);
  },
  formatAndSetTimeToEndTimer ({ dispatch }, { unit, cnt }) {
    const formattedTime = formatLmsOtpTime({ unit, cnt });

    dispatch("changeTimeToEndTimer", formattedTime);
  },
  setAllowedToValidateOtp ({ commit }, status) {
    commit("setAllowedToValidateOtp", status);
  },
  async checkOtpRemainingAttempts ({ dispatch }) {
    const otpRemainingAttemptsResponse = await dispatch("fetchOTPRemainingAttempts");

    if (otpRemainingAttemptsResponse?.response?.block) {
      await dispatch("node/transitionToNextNode", {}, { root: true });
      throw new Error("User is blocked. Redirect to error screen");
    }
    
    return otpRemainingAttemptsResponse?.response?.block;
  },
  async validateOTPCode ({ rootState, state, dispatch }) {
    if (!state.allowedToValidateOtp) return;

    dispatch("node/setIsFetchingScreen", true, { root: true });
    
    const currentCode = rootState.node.screen?.currentScreenData?.otpSmsCode || "";
    const validateOTPResponse = await dispatch("fetchValidateOTP", currentCode);

    if (validateOTPResponse?.status === 1) {
      // Успешная валидация, идем на анкету
      await dispatch("node/transitionToNextNode", {}, { root: true });
    } else {
      dispatch("node/setIsFetchingScreen", false, { root: true });
      dispatch("setShowValidateOtpError", true);
      // Проверка блокировки при ошибке валидации
      await dispatch("checkOtpRemainingAttempts");
    }
  },
  async checkIsAllowedScreenToStart4DigsOTPCheck ({ rootState, dispatch }) {
    const otp4DigsScreenName = "WebAuthentication.otpCheckMobile.screen.1";
    const currentScreenName = rootState.node.screen?.currentScreen?.name;
    
    if (otp4DigsScreenName === currentScreenName) {
      await dispatch("check4DigsOTPStatus");
    }
  },
  async check4DigsOTPStatus ({ dispatch }) {
    try {
      dispatch("setAllowedToValidateOtp", false);
      
      // Проверка блокировки пользователя
      await dispatch("checkOtpRemainingAttempts");
      
      const otpRemainingTimeResponse = await dispatch("fetchOTPRemainingTime");

      if (otpRemainingTimeResponse?.message === "OTP code not found") {
        const generateOTPResponse = await dispatch("fetchGenerateOTP");

        if (generateOTPResponse?.status === 1) {
          dispatch("formatAndSetTimeToEndTimer", {
            unit: generateOTPResponse?.response?.unit,
            cnt: generateOTPResponse?.response?.cnt,
          });
          
          dispatch("setAllowedToValidateOtp", true);
        }
        
        if (generateOTPResponse?.status === 0) {
          return await dispatch("node/transitionToNextNode", {}, { root: true });
        }
      } else if (otpRemainingTimeResponse?.status === 1) {
        dispatch("formatAndSetTimeToEndTimer", {
          unit: otpRemainingTimeResponse?.response?.unit,
          cnt: otpRemainingTimeResponse?.response?.cnt,
        });

        dispatch("setAllowedToValidateOtp", true);
      }
    } catch (e) {
      console.error("OTP check error:", e);
    }
  },
};

const getters = {
  timeToEnd4DigsOtpTimer (state) {
    return state.timeToEndTimer;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
