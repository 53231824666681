import LmsApi from "@/services/lms";
import formatLmsOtpTime from "@/utils/formatLmsOtpTime";

const state = {
  timeToEndTimer: null,
  showValidateOtpError: false,
  allowedToValidateOtp: false,
  errorCode: null,
  errorMessage: null,
};

const mutations = {
  setTimeToEndTimer (state, time) {
    state.timeToEndTimer = time;
  },
  setShowValidateOtpError (state, show) {
    if (state.showValidateOtpError === show) return;
    state.showValidateOtpError = show;
  },
  setAllowedToValidateOtp (state, status) {
    state.allowedToValidateOtp = status;
  },
  setErrorCode(state, code) {
    state.errorCode = code;
  },
  setErrorMessage(state, message) {
    state.errorMessage = message;
  },
};

const actions = {
  async fetchNumberOfAttemptsOkyc () {
    const response = await LmsApi.numberOfAttemptsOkyc();
    return response?.data;
  },
  async fetchOkycInitiateKycAuto () {
    const response = await LmsApi.okycInitiateKycAuto();
    return response?.data;
  },
  async fetchOkycSubmitOtp (_, code) {
    const response = await LmsApi.okycSubmitOtp({ code });
    return response?.data;
  },
  async fetchOkycResendOtp () {
    const response = await LmsApi.okycResendOtp();
    return response?.data;
  },
  async checkNumberOfAttempts ({ dispatch }) {
    try {
      const { response } = await dispatch("fetchNumberOfAttemptsOkyc");

      const { countOkycInitiateKycAuto, countOkycSubmitOtp } = response;

      // Let's hope 0 is not a string :)
      if (!countOkycInitiateKycAuto || !countOkycSubmitOtp) {
        return await dispatch("node/transitionToNextNode", {}, { root: true });
      }
      
      return true;
    } catch (e) {
      throw new Error(`Error: checkNumberOfAttempts: ${e}`);
    }
  },
  async resendOTPCode ({ dispatch, commit }) {
    const { response } = await dispatch("fetchOkycResendOtp");
    const { errorCode } = response;
    
    if (errorCode === "E0007") {
      commit("setErrorCode", errorCode);
      dispatch("setAllowedToValidateOtp", false);
      
      return;
    }
    
    const isAllowed = await dispatch("checkNumberOfAttempts");
    
    if (isAllowed) dispatch("setAllowedToValidateOtp", true);
    
    // await dispatch("check6DigsOTPStatus");
  },
  async validateOTPCode ({ rootState, state, dispatch, commit }) {
    if (!state.allowedToValidateOtp) return;

    dispatch("node/setIsFetchingScreen", true, { root: true });
    commit("setErrorCode", null);

    const otp = rootState.node.screen?.currentScreenData?.otpThirdPartyCode || "";
    const response = await dispatch("fetchOkycSubmitOtp", otp);

    const { code, errorCode } = response;
    
    if (errorCode === "E0010") {
      dispatch("setAllowedToValidateOtp", false);
      commit("setErrorCode", errorCode);
      
      await dispatch("checkNumberOfAttempts");
      dispatch("node/setIsFetchingScreen", false, { root: true });
    }
    
    if (code === "500") {
      return await dispatch("node/transitionToNextNode", {}, { root: true });
    }

    if (code === "200") {
      return await dispatch("node/transitionToNextNode", {}, { root: true });
    }
  },
  setShowValidateOtpError ({ commit }, show) {
    commit("setShowValidateOtpError", show);
  },
  changeTimeToEndTimer ({ commit }, time) {
    commit("setTimeToEndTimer", time);
  },
  formatAndSetTimeToEndTimer ({ dispatch }, { unit, cnt }) {
    const formattedTime = formatLmsOtpTime({ unit, cnt });

    dispatch("changeTimeToEndTimer", formattedTime);
  },
  setAllowedToValidateOtp ({ commit }, status) {
    commit("setAllowedToValidateOtp", status);
  },
  async checkIsAllowedScreenToStart6DigsOTPCheck ({ rootState, dispatch }) {
    const otp6DigsScreenName = "WebAuthentication.thirdPartyOtpCheck.screen.1";
    const currentScreenName = rootState.node.screen?.currentScreen?.name;

    if (otp6DigsScreenName === currentScreenName) {
      await dispatch("check6DigsOTPStatus");
    }
  },
  async check6DigsOTPStatus ({ dispatch, commit }) {
    try {
      dispatch("setAllowedToValidateOtp", false);
      commit("setErrorCode", null);
      commit("setErrorMessage", null);

      await dispatch("checkNumberOfAttempts");

      // Generate OTP
      const okycInitiateKycAutoResponse = await dispatch("fetchOkycInitiateKycAuto");
      
      const { errorCode, code, msg } = okycInitiateKycAutoResponse;

      if (
        code === "500"
        || code === "503"
        || code === "400"
        || errorCode === "E0006"
      ) {
        return await dispatch("node/transitionToNextNode", {}, { root: true });
      }

      if (errorCode) {
        commit("setErrorCode", errorCode);
        commit("setErrorMessage", msg);
      }

      dispatch("setAllowedToValidateOtp", false);

      // Вообще не факт, что будет такой формат
      // + время должно приходить где-то еще, не только с генерации
      const { unit, cnt } = okycInitiateKycAutoResponse;
      
      dispatch("formatAndSetTimeToEndTimer", { unit, cnt });
      
      const isAllowed = await dispatch("checkNumberOfAttempts");
      
      if (isAllowed) {
        dispatch("setAllowedToValidateOtp", true);
        commit("setErrorCode", null);
      }
      
    } catch (e) {
      console.error("OTP check error:", e);
    }
  },
};

const getters = {
  timeToEnd6DigsOtpTimer (state) {
    return state.timeToEndTimer;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
